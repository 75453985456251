
import { defineComponent, computed, watch } from 'vue';
import { useRoute } from 'vue-router';
import routeParamToString from '@/router/param_as_string';
import { isLoaded, isLoading } from '@/interfaces/RemoteData';
import { getPageDetail, pageDetail, manuscriptDetail } from '@/api/manuscripts';
import { manuscriptWeb } from '@/api/web';
import PageNav from '@/components/manuscripts/PageNav.vue';
import LoadingSpinner from '@/components/global/LoadingSpinner.vue';
import TwoItems from '@/components/global/TwoItems.vue';
import Card from '@/components/global/Card.vue';
import ReferenceVerses from '@/components/manuscripts/ReferenceVerses.vue';
import MaybePageImages from '@/components/manuscripts/MaybePageImages.vue';
import TransliterationKey from '@/components/manuscripts/TransliterationKey.vue';
import { useMeta } from 'vue-meta';
import { useI18n } from 'vue-i18n';
import { manuscriptTitle } from '@/interfaces/ManuscriptDetail';

export default defineComponent({
  components: {
    PageNav,
    LoadingSpinner,
    TwoItems,
    MaybePageImages,
    Card,
    ReferenceVerses,
    TransliterationKey,
  },
  setup() {
    const { t } = useI18n({
      useScope: 'global',
      inheritLocale: true,
    });
    const route = useRoute();
    const pagetitle = computed(() => {
      if (isLoaded(manuscriptDetail.value)) {
        return manuscriptTitle(manuscriptDetail.value.payload);
      }
      return '';
    });

    const title = computed(() => {
      const begin = `${pagetitle.value} > ${t('navigation.manuscripts')} `;

      if (route.params.verse) {
        const verse = `${t('global.sura')} ${route.params.sura} ${t('global.verse')} ${route.params.verse}`;
        return begin.concat(verse);
      }
      return begin;
    });

    const description = computed(() => {
      if (route.params.verse) {
        return `${pagetitle.value} > ${t('navigation.manuscripts')} ${t('global.sura')} ${
          route.params.sura
        } ${t('global.verse')} ${route.params.verse}`;
      }
      return `${pagetitle.value} > ${t('navigation.manuscripts')}`;
    });

    const canonicalurl = computed(
      () => `https://corpuscoranicum.org/${route.params.lang}/manuscripts/${route.params.manuscript}/page/${route.params.page}`,
    );
    const alternate_de = computed(
      () => `https://corpuscoranicum.org/de/manuscripts/${route.params.manuscript}/page/${route.params.page}`,
    );
    const alternate_en = computed(
      () => `https://corpuscoranicum.org/en/manuscripts/${route.params.manuscript}/page/${route.params.page}`,
    );
    const alternate_fr = computed(
      () => `https://corpuscoranicum.org/fr/manuscripts/${route.params.manuscript}/page/${route.params.page}`,
    );
    const meta = computed(() => ({
      title: title.value,
      description: description.value,
      link: [
        {
          rel: 'canonical',
          href: canonicalurl.value,
        },
        {
          rel: 'alternate',
          hreflang: 'de',
          href: alternate_de.value,
        },
        {
          rel: 'alternate',
          hreflang: 'en',
          href: alternate_en.value,
        },
        {
          rel: 'alternate',
          hreflang: 'fr',
          href: alternate_fr.value,
        },
        {
          rel: 'alternate',
          hreflang: 'x-default',
          href: alternate_de.value,
        },
      ],
      htmlAttrs: { lang: route.params.lang },
    }));
    useMeta(meta);
    getPageDetail(
      routeParamToString(route.params.lang),
      +routeParamToString(route.params.manuscript),
      routeParamToString(route.params.page),
    );

    watch([() => route.params], () => {
      if (route.name === 'ManuscriptPage') {
        getPageDetail(
          routeParamToString(route.params.lang),
          +routeParamToString(route.params.manuscript),
          routeParamToString(route.params.page),
        );
      }
    });

    return {
      pageDetail,
      isLoaded,
      isLoading,
      manuscriptWeb,
    };
  },
});
