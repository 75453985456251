
import { computed, defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import NavigationLink from '@/interfaces/NavigationLink';

export default defineComponent({
  setup() {
    const {
      t, locale,
    } = useI18n({
      useScope: 'global',
      inheritLocale: true,
    });
    const linksSetOne = computed(() => [
      // {
      //   label: () => t('navigation.faq'),
      //   to: {
      //     name: 'FAQ',
      //     params: { lang: locale.value },
      //   },
      //   active: [],
      // },
      {
        label: () => t('navigation.contact'),
        to: {
          name: 'Contact',
          params: { lang: locale.value },
        },
        active: [],
      },
      {
        label: () => t('navigation.impressum'),
        to: {
          name: 'Impressum',
          params: { lang: locale.value },
        },
        active: [],
      },
      {
        label: () => t('navigation.privacy_policy'),
        to: {
          name: 'DataProtection',
          params: { lang: locale.value },
        },
        active: [],
      },
    ]);

    const linksSetTwo = computed(() => [
      // {
      //   label: () => t('navigation.about'),
      //   to: {
      //     name: 'AboutTheProject',
      //     params: { lang: locale.value },
      //   },
      //   active: [],
      // },
      // {
      //   label: () => t('navigation.bibliography'),
      //   to: {
      //     name: 'Sources',
      //     hash: '#zotero',
      //     params: { lang: locale.value },
      //   },
      //   active: [],
      // },
      {
        label: () => t('navigation.coranica_font'),
        to: {
          name: 'Tools',
          hash: '#coranicafont',
          params: { lang: locale.value },
        },
        active: [],
      },
    ]);
    return {
      linksSetOne,
      linksSetTwo,
    };
  },
});
